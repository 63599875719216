#payment-card-icon-wrapper.payment-card__brand-consumer_credits .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/190e7bc0-0402-11ef-98be-75e339f4ba8a-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-consumer_credits { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-visa .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/9042e980-7503-11ee-b8a0-4d709b67ff2a-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-visa { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-amex .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/80959e90-74f6-11ee-90cd-f389c3b4dbd4-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-amex { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-master .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/ed5f3c50-7502-11ee-90cd-f389c3b4dbd4-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-master { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-cabal .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/ffbb0ed0-74f6-11ee-b8a0-4d709b67ff2a-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-cabal { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-debmaster .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/39a93940-7502-11ee-8a21-f7cc79dd73f5-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-debmaster { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-maestro .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/c20739c0-74ff-11ee-8306-c515c1eba7d0-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-maestro { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-debvisa .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/2dba7030-7756-11ee-8a21-f7cc79dd73f5-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-debvisa { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-account_money .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/5ec6cce0-0401-11ef-abe6-ef0735c1c9af-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-account_money { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-naranja .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/61d803b0-0431-11ef-9eaf-6f9218b4567a-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-naranja { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-pagofacil .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/f897ae20-3a66-11ef-97e6-2f5aa9c07e04-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-pagofacil { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-tarshop .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/b9a25d50-9ebc-11ee-9af3-afd2eb6c9602-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-tarshop { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-argencard .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/e42ecf50-9ebb-11ee-9af3-afd2eb6c9602-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-argencard { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-cencosud .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/2375b160-9ebc-11ee-826f-b79887bc5c6d-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-cencosud { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-diners .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/4f4b8a60-774a-11ee-90cd-f389c3b4dbd4-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-diners { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-rapipago .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/572890a0-3a47-11ef-99a7-2d9e35254841-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-rapipago { padding: 0; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-debcabal .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/25a0dd30-0432-11ef-abe6-ef0735c1c9af-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-debcabal { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-cordobesa .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/29ccaa80-7501-11ee-8306-c515c1eba7d0-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-cordobesa { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-cmr .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/37b87ae0-7504-11ee-8306-c515c1eba7d0-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-cmr { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-pago-facil .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/94a76d00-4444-11ef-988e-99f93799e169-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-pago-facil { padding: 0; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-visa {
      padding: 0;
    }
    #payment-card-icon-wrapper.payment-card__brand-visa .payment-card-icon {
      max-width: 24px;
    }