#payment-card-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px var(--andes-gray-070-solid, #ededed);
}

#payment-card-icon-wrapper .payment-card-icon {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(1);
}

#payment-card-icon-wrapper.payment-card-icon-wrapper {
  padding: 9px;
}

#payment-card-icon-wrapper.payment-card-icon-wrapper .payment-card-icon {
  background-image: url(../../../assets/images/payment-card-logos/card-default.svg);
}
